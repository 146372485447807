import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap"

import logo from "../images/logo.jpg"

const Header = () => (
  <header className="py-4">
    <Container>
      <Navbar bg="transparent" expand="lg">
        <Col xs={8} md={6} lg={4}>
          <Link to="/">
            <img src={logo} alt="Uredale Glass" className="img-fluid" />
          </Link>
        </Col>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto mt-4 mt-lg-0">
            <Nav.Item>
              <Link to="/" activeClassName="active" className="nav-link">
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/hot-glass"
                activeClassName="active"
                className="nav-link"
              >
                Hot Glass
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/fused-glass"
                activeClassName="active"
                className="nav-link"
              >
                Fused Glass
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/stained-glass"
                activeClassName="active"
                className="nav-link"
              >
                Stained Glass
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/courses"
                activeClassName="active"
                className="nav-link"
              >
                Courses
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/contact-us"
                activeClassName="active"
                className="nav-link"
              >
                Contact Us
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Row>
        <Col xs={4}>
          <Link to="/"></Link>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
