import React from "react"
import { Container } from "react-bootstrap"

const Footer = () => {
  return (
    <footer className="bg-primary py-5 text-white text-center">
      <p>01765 689 780 / info@uredale.co.uk</p>
      <p>
        Uredale Glass, Market Place, Masham, Ripon, North Yorkshire, HG4 4EF
      </p>
      <Container>© {new Date().getFullYear()} Uredale Glass</Container>
    </footer>
  )
}

export default Footer
